import { ChevronDoubleUpIcon, XIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Answer } from "../../../interfaces/answer";
import { CollectionInterface } from "../../../interfaces/api";
import { Question } from "../../../interfaces/question";
import { Vote } from "../../../interfaces/vote";
import { postMyVote, selectActiveQuestion } from "../../../redux/sessionSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import { mercureSubscribe } from "../../../utils/mercure";
/** @ts-ignore */
import beep from "../../../media/sounds/beep.mp3";

function ActiveQuestion() {
  const [minimized, setMinimized] = useState(true);

  const activeQuestion = useSelector((state: RootState) =>
    selectActiveQuestion(state.session)
  );
  const [question, setQuestion] = useState<Question>(); //Nos permite mantener abierta la pregunta aún luego de ser terminada
  const [votes, setVotes] = useState<Vote[]>(); //Para hacer el conteo

  const newQuestionSound = new Audio(beep);
  newQuestionSound.loop = false;

  useEffect(() => {
    if (!question) return;

    newQuestionSound.play();

    fetchVotes(question["@id"]);
    // mercureSubscribe(
    //   `${process.env.REACT_APP_MERCURE_HUB}`, //el servidor
    //   `${question["@id"]}/votes`, //el topic
    //   () => fetchVotes(question["@id"]) //la accion cuando me devuelve los datos
    // );
  }, [question, activeQuestion]);

  const fetchVotes = (questionId: string) => {
    let url = new URL(`${process.env.REACT_APP_BACKEND_URL}/api/votes`);
    url.searchParams.append("question", questionId);

    fetch(url.href, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        response
          .json()
          .then((data: CollectionInterface<Vote>) =>
            setVotes(data["hydra:member"])
          );
      }
    });
  };

  let active: boolean = question === activeQuestion; //Indica si aún se puede votar

  /**
   * Cada vez que cambia la pregunta activa
   * desde el servidor, se maximiza la ventana y se guarda en el estado
   */
  useEffect(() => {
    setMinimized(false);
    activeQuestion && setQuestion(activeQuestion);
  }, [activeQuestion]);

  /**
   * Si cierro la ventana, cuando la pregunta ya habia terminado
   * no minimiza, sino que lo cierra
   */
  useEffect(() => {
    if (minimized && !activeQuestion) setQuestion(undefined);
  }, [minimized]);

  const dispatch: AppDispatch = useDispatch();

  if (!question) return null;
  return minimized ? (
    <button
      onClick={() => setMinimized(false)}
      className="p-4 pb-2 italic bg-purple-700 text-white w-full fixed bottom-0 left-0 text-center"
    >
      <ChevronDoubleUpIcon className="animate-bounce w-6 h-6 m-auto" />
      <p className="text-xs">seguir con la votación ...</p>
    </button>
  ) : (
    <div className="p-4 bg-purple-700 text-white min-h-screen w-full absolute top-0 left-0 flex items-center justify-center">
      <button
        onClick={() => setMinimized(true)}
        className="absolute top-2 right-2"
      >
        <XIcon className="w-8 h-8" />
      </button>
      <div className="max-w-xl w-full mt-12">
        <h2 className="font-semibold mb-12 text-lg text-justify whitespace-pre-wrap">
          {question.text}
        </h2>
        {question.answers?.map((answer) => (
          <VoteButton
            active={active}
            answer={answer}
            votes={votes}
            key={answer["@id"]}
          />
        ))}
        <VoteButton active={active} votes={votes} />
      </div>
    </div>
  );
}

export default ActiveQuestion;

/**
 *  BOTÓN PARA VOTAR
 */

function VoteButton({
  answer,
  active,
  votes,
}: {
  answer?: Answer;
  active: boolean;
  votes?: Vote[];
}) {
  const dispatch: AppDispatch = useDispatch();
  const myVote = useSelector((state: RootState) => state.session.myVote);

  return (
    <div className="relative bg-purple-800 mb-4">
      <VotesResultBar votes={votes} answer={answer} active={active} />

      <button
        onClick={() => active && dispatch(postMyVote(answer?.["@id"]))}
        className={classNames(
          "whitespace-pre-wrap relative p-3 pl-16 border-2 border-transparent text-justify w-full",
          myVote?.answer === (answer?.["@id"] || null) && "border-white",
          !active && "cursor-not-allowed"
        )}
      >
        <div className="flex items-center justify-center w-full">
          {myVote?.answer === (answer?.["@id"] || null) && (
            <div className="absolute left-4">
              <CheckCircleIcon className="w-8 h-8" />
            </div>
          )}
          <div className={classNames("flex-1", active || "opacity-50")}>
            {answer?.text || "Abstenerme"}
          </div>
        </div>
        {!active && (
          <VotesResultCount votes={votes} answer={answer} active={active} />
        )}
      </button>
    </div>
  );
}

function VotesResultBar({
  answer,
  votes,
  active,
}: {
  answer?: Answer;
  votes?: Vote[];
  active: boolean;
}) {
  let total = votes?.length || 0;
  let count =
    votes?.filter((vote) => vote.answer === (answer?.["@id"] || null)).length ||
    0;
  let percentage = Math.floor((count * 100) / total);
  return (
    <div
      className="h-full bg-purple-900 absolute left-0 transition-[width] duration-700 ease-out"
      style={{ width: active ? "0" : `${percentage}%` }}
    ></div>
  );
}

function VotesResultCount({
  answer,
  votes,
  active,
}: {
  answer?: Answer;
  votes?: Vote[];
  active: boolean;
}) {
  let total = votes?.length || 0;
  let count =
    votes?.filter((vote) => vote.answer == answer?.["@id"] || null).length || 0;
  return count ? (
    <div className="my-6">
      <h3 className="font-bold">
        {count} {count === 1 ? "voto" : "votos"} de {total}
      </h3>
      <div className="text-xs">
        {votes
          ?.filter((vote) => vote.answer == answer?.["@id"] || null)
          .map((vote, index, votes) => {
            return (
              vote.user?.displayName && (
                <span>
                  {vote.user?.displayName +
                    (votes.length !== index + 1 ? ", " : "")}
                </span>
              )
            );
          })}
      </div>
    </div>
  ) : null;
}
