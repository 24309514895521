import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Init from "./components/Init";
import RequireAuth from "./components/security/RequireAuth";
import NoMatch404 from "./pages/NoMatch404";
import Admin from "./pages/admin/Admin";
import Login from "./pages/security/Login";
import { getUser } from "./redux/securitySlice";
import { AppDispatch, RootState } from "./redux/store";
import { default as AdminSession } from "./pages/admin/session/Session";
import Session from "./pages/session/Session";
import Dashboard from "./pages/admin/Dashboard";
import { QueryClient, QueryClientProvider } from "react-query";
import HomeLayout from "./components/layout/HomeLayout";
import Results from "./pages/admin/session/Results";

export const enum Paths {
  home = "/",
  login = "/login",
  session = "/s",
  admin = "/admin",
  admin_sessions = "sessions",
  admin_sessions_results = "sessions/results",
  admin_users = "users",
}

function App() {
  const state = useSelector((state: RootState) => state);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser());
  }, []);

  const queryClient = new QueryClient();

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        {state.security.requested ? (
          <>
            <Routes>
              <Route path={Paths.login} element={<Login />} />
              <Route
                path={Paths.admin}
                element={
                  <RequireAuth role="ROLE_ADMIN">
                    <HomeLayout>
                      <Admin />
                    </HomeLayout>
                  </RequireAuth>
                }
              >
                <Route index element={<Dashboard />} />
                <Route
                  path={`${Paths.admin_sessions}/:sessionId`}
                  element={<AdminSession />}
                />
                <Route
                  path={`${Paths.admin_sessions}/:sessionId/results`}
                  element={<Results />}
                />
              </Route>
              <Route
                path={`${Paths.session}/:sessionId`}
                element={
                  <RequireAuth>
                    <HomeLayout>
                      <Session />
                    </HomeLayout>
                  </RequireAuth>
                }
              />

              <Route path="*" element={<NoMatch404 />} />
            </Routes>
          </>
        ) : (
          <Init />
        )}
      </QueryClientProvider>
    </div>
  );
}

export default App;
