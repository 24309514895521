import React, { useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Init from "../../components/Init";
import { fetchParticipants, fetchSession } from "../../redux/sessionSlice";
import { AppDispatch, RootState } from "../../redux/store";
import NoMatch404 from "../NoMatch404";
import ActiveSession from "./ActiveSession";
import { Session as SessionInterface } from "../../interfaces/session";
import { mercureSubscribe } from "../../utils/mercure";

function Session() {
  const params = useParams();
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state.session);
  const sessionId = params.sessionId;

  useLayoutEffect(() => {
    sessionId &&
      dispatch(
        fetchSession(
          sessionId,
          //------------------------------------------------------------
          //   ONSUCCESS SE SUSCRIBE A CAMBIOS REPORTADOS POR MERCURE HUB
          (data: SessionInterface) => {
            mercureSubscribe(
              `${process.env.REACT_APP_MERCURE_HUB}`, //el servidor
              `${process.env.REACT_APP_BACKEND_URL}${data["@id"]}`, //el topic
              (data: SessionInterface) => dispatch(fetchSession(data.id)) //la accion cuando me devuelve los datos
            );
          }
          //------------------------------------------------------------
        )
      );
  }, []);

  useLayoutEffect(() => {
    state.session &&
      dispatch(
        fetchParticipants(
          //------------------------------------------------------------
          //   ONSUCCESS SE SUSCRIBE A CAMBIOS REPORTADOS POR MERCURE HUB
          (data: SessionInterface) => {
            mercureSubscribe(
              `${process.env.REACT_APP_MERCURE_HUB}`, //el servidor
              `${data["@id"]}/participants`, //el topic
              (data: SessionInterface) => dispatch(fetchParticipants()) //la accion cuando me devuelve los datos
            );
          }
          //------------------------------------------------------------
        )
      );
  }, [state.session?.["@id"]]);

  if (!state.requested) return <Init />;
  if (state.error) return <NoMatch404 />;
  if (state.session?.status === "finished")
    return (
      <div className="flex-1 flex items-center justify-center">
        <div className="font-black text-5xl text-gray-500">
          El evento finalizó
        </div>
      </div>
    );
  if (state.session?.status === "pending")
    return (
      <div className="flex-1 flex items-center justify-center">
        <div className="font-black text-5xl text-gray-500">
          El evento aún no comenzó
        </div>
      </div>
    );
  return <ActiveSession />;
}

export default Session;
