import classNames from "classnames";
import React, { ReactElement } from "react";
import HelpInfo from "../HelpInfo";

type InputProps = {
  label?: string;
  help?: string;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextArea = React.forwardRef<HTMLTextAreaElement, InputProps>(
  ({ className, help, label, ...props }, ref): ReactElement => {
    return (
      <div>
        {label && (
          <label className="block mb-1 text-base font-semibold sm:text-sm">
            {label}
            {props.required && <span className="text-red-600"> *</span>}
            {help && (
              <p className="text-xs text-gray-600 mb-2 font-light">{help}</p>
            )}
          </label>
        )}
        <textarea
          {...props}
          ref={ref}
          className={classNames(
            "p-2 shadow-sm h-32 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md",
            className
          )}
        />
      </div>
    );
  }
);

export default TextArea;
