import { combineReducers, configureStore } from "@reduxjs/toolkit";
import securitySlice from "./securitySlice";
import sessionsSlice from "./admin/sessionsSlice";
import sessionSlice from "./sessionSlice";

export const rootReducer = combineReducers({
  security: securitySlice,
  session: sessionSlice,
  admin_sessions: sessionsSlice,
});

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
