import { DocumentTextIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Answer } from "../../../../interfaces/answer";
import { CollectionInterface } from "../../../../interfaces/api";
import { Participant } from "../../../../interfaces/participant";
import { Vote } from "../../../../interfaces/vote";
import {
  selectActiveQuestion,
  setActiveQuestion,
} from "../../../../redux/sessionSlice";
import { AppDispatch, RootState } from "../../../../redux/store";
import { mercureSubscribe } from "../../../../utils/mercure";

function ActiveQuestion() {
  const dispatch: AppDispatch = useDispatch();
  const [votes, setVotes] = useState<Vote[]>();

  //   https://redux.js.org/usage/deriving-data-selectors#createselector-overview
  const question = useSelector((state: RootState) =>
    selectActiveQuestion(state.session)
  );

  useEffect(() => {
    if (!question) return;
    fetchVotes(question["@id"]);
    mercureSubscribe(
      `${process.env.REACT_APP_MERCURE_HUB}`, //el servidor
      `${question["@id"]}/votes`, //el topic
      () => fetchVotes(question["@id"]) //la accion cuando me devuelve los datos
    );
  }, [question?.["@id"]]);

  const fetchVotes = (questionId: string) => {
    let url = new URL(`${process.env.REACT_APP_BACKEND_URL}/api/votes`);
    url.searchParams.append("question", questionId);

    fetch(url.href, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        response
          .json()
          .then((data: CollectionInterface<Vote>) =>
            setVotes(data["hydra:member"])
          );
      }
    });
  };

  return question ? (
    <div className="p-8 bg-purple-800 text-white rounded-lg mb-6">
      <h2 className="font-semibold">{question.text}</h2>
      {question.notes && (
        <div className="flex text-purple-200 mt-5">
          <DocumentTextIcon className="w-5 h-5 mr-2" />
          <p className="text-xs font-mono pt-1">{question.notes}</p>
        </div>
      )}
      <div className="mt-6">
        {question.answers?.map((answer) => (
          <AnswerOption key={answer["@id"]} votes={votes} answer={answer} />
        ))}
        <AnswerOption votes={votes} />
        <PendingVotes votes={votes} />
      </div>
      <button
        className="border border-white p-2 rounded-lg w-full my-4"
        onClick={() => dispatch(setActiveQuestion(null))}
      >
        TERMINAR CONSULTA
      </button>
    </div>
  ) : null;
}

export default ActiveQuestion;

function AnswerOption({ answer, votes }: { answer?: Answer; votes?: Vote[] }) {
  return (
    <div className="flex flex-wrap flex-row items-center mb-8 bg-purple-900 p-4">
      <div className="w-1/2 mr-6">{answer?.text || "Abstenciones"}</div>
      <div className="flex-1 text-center">
        <div className="font-semibold">
          {
            votes?.filter((vote) => vote.answer === (answer?.["@id"] || null))
              .length
          }{" "}
          VOTOS
        </div>
        <div className="text-xs">
          {votes
            ?.filter((vote) => vote.answer === (answer?.["@id"] || null))
            .map((vote, index, votes) => {
              return (
                <span>
                  {vote.user?.displayName &&
                    vote.user?.displayName +
                      (votes.length !== index + 1 ? ", " : "")}
                </span>
              );
            })}
        </div>
      </div>
    </div>
  );
}

function PendingVotes({ votes }: { votes?: Vote[] }) {
  const participants = useSelector(
    (state: RootState) => state.session.participants
  );

  let pending: Participant[] | undefined = participants?.filter(
    (p) => p.joined && !votes?.some((v) => v.user?.["@id"] === p.user["@id"])
  );

  return pending ? (
    <div className="flex flex-wrap flex-row items-center mb-8 bg-purple-900 p-4">
      <div className="w-1/2 mr-6">Pendientes</div>
      <div className="flex-1 text-center">
        <div className="font-semibold">{pending.length} VOTOS</div>
        <div className="text-xs">
          {pending.map((participant, index, votes) => {
            return (
              <span>
                {participant.user?.displayName +
                  (votes.length !== index + 1 ? ", " : "")}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  ) : null;
}
