import React from "react";

function Forbidden403() {
  return (
    <div className="h-screen w-full flex justify-center items-center text-gray-600">
      <div className="text-center">
        <div className="text-5xl font-extrabold">403</div>
        <div className="font-mono text-xs">
          Ingresa con una cuenta de administrador
          <br />
          para acceder a esto
        </div>
      </div>
    </div>
  );
}

export default Forbidden403;
