import classNames from "classnames";
import React, { ReactElement } from "react";
import HelpInfo from "../HelpInfo";

type InputProps = {
  label?: string;
  help?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, help, label, ...props }, ref): ReactElement => {
    return (
      <div>
        {label && (
          <label className="block mb-1 text-base font-semibold sm:text-sm">
            {label}
            {props.required && <span className="text-red-600"> *</span>}
            {help && (
              <span className="ml-2">
                <HelpInfo>
                  <p className="text-sm text-white">{help}</p>
                </HelpInfo>
              </span>
            )}
          </label>
        )}
        <input
          {...props}
          ref={ref}
          className={classNames(
            "relative w-full px-4 py-2 text-left text-gray-900 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm",
            className
          )}
        />
      </div>
    );
  }
);

export default Input;
