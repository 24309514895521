import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import { Participant } from "../interfaces/participant";
import { Session } from "../interfaces/session";
import { User } from "../interfaces/user";
import { RootState } from "./store";

interface securityState {
  user?: User;
  isLoading: boolean;
  requested: boolean;
  error: string | null;
}

const initialState: securityState = {
  isLoading: false,
  requested: false,
  error: null,
};

const securitySlice = createSlice({
  name: "security",
  initialState: initialState,
  reducers: {
    requestStart(state) {
      state.isLoading = true;
      state.error = null;
      state.user = undefined;
    },
    requestSuccess(state, action: PayloadAction<User>) {
      state.isLoading = false;
      state.requested = true;
      state.error = null;
      state.user = action.payload;
    },
    requestError(state) {
      state.isLoading = false;
      state.requested = true;
      state.user = undefined;
    },
    loginStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.user = action.payload;
    },
    loginError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    logoutSuccess(state) {
      state.user = undefined;
    },
  },
});

const { actions, reducer } = securitySlice;

export const {
  requestStart,
  requestError,
  requestSuccess,
  loginError,
  loginStart,
  loginSuccess,
  logoutSuccess,
} = actions;

//     S   E   L   E   C   T   O   R   E   S
// https://redux.js.org/usage/deriving-data-selectors#createselector-overview

const selectUser = (state: RootState) => state.security.user;
const selectParticipants = (state: RootState) => state.session.participants;

// Devuelve los permisos del usuario
export const selectUserGranted = createSelector(
  [selectUser, selectParticipants],
  (user, participants): string[] => {
    let permisions = new Set<string>();
    user?.roles?.forEach((role) => permisions.add(role));
    if (
      participants?.find(
        (participant) =>
          participant.user["@id"] === user?.["@id"] && participant.host
      )
    )
      permisions.add("ROLE_HOST");

    if (
      participants?.find(
        (participant) => participant.user["@id"] === user?.["@id"]
      )
    )
      permisions.add("ROLE_PARTICIPANT");
    return Array.from(permisions);
  }
);

/******************************************************************************
 * https://redux.js.org/tutorials/essentials/part-5-async-logic#thunk-functions
 ******************************************************************************/

/**
 * Obtiene los datos del miembros del servidor.
 * Llamado al cargar el sitio por privmera vez
 */
export const getUser =
  (
    { silent, onSuccess }: { silent: boolean; onSuccess?: Function } = {
      silent: false,
    }
  ) =>
  async (dispatch: Dispatch) => {
    !silent && dispatch(requestStart());
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/me`, {
      credentials: "include",
    });
    if (!res.ok) dispatch(requestError());
    //No muestro un error acá porque tendria un error cada vez que abro la web e intento obtener los datos del usuario
    else {
      dispatch(requestSuccess(await res.json()));
      onSuccess && onSuccess();
    }
  };

/**
 * El usuario se loguea con usuario y contraseña
 */
export const login =
  (data: { username: string; password: string }, onSuccess?: () => void) =>
  async (dispatch: Dispatch) => {
    dispatch(loginStart());
    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/login`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!res.ok)
      res
        .json()
        .then((data) => dispatch(loginError(data.error || data.detail)));
    else {
      dispatch(loginSuccess(await res.json()));
      onSuccess && onSuccess();
    }
  };

/**
 * Logout
 */

export const logout = (onSuccess: () => void) => async (dispatch: Dispatch) => {
  fetch(`${process.env.REACT_APP_BACKEND_URL}/api/logout`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    dispatch(logoutSuccess());
    onSuccess && onSuccess();
  });
};

export default reducer;
