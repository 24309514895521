import React from "react";
import Loader from "./Loader";

/**
 *   Muestra un splash al inicio mientras consulta al servidor
 *   por los datos del usuario (si los hay)
 */

function Init() {
  return (
    <div className="absolute top-0 left-0 w-full flex content-center items-center justify-center h-screen bg-white">
      <Loader />
    </div>
  );
}

export default Init;
