import classNames from "classnames";
import React, { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Paths } from "../../../App";
import { AppDispatch, RootState } from "../../../redux/store";
import dateFormat, { masks } from "dateformat";
import { useDispatch } from "react-redux";
import { fetchParticipants } from "../../../redux/sessionSlice";
import { Session as SessionInterface } from "../../../interfaces/session";
import { mercureSubscribe } from "../../../utils/mercure";

function Sessions() {
  const state = useSelector((state: RootState) => state);
  const sessions = state.admin_sessions.sessions;

  const dispatch: AppDispatch = useDispatch();

  useLayoutEffect(() => {
    state.session &&
      dispatch(
        fetchParticipants(
          //------------------------------------------------------------
          //   ONSUCCESS SE SUSCRIBE A CAMBIOS REPORTADOS POR MERCURE HUB
          (data: SessionInterface) => {
            mercureSubscribe(
              `${process.env.REACT_APP_MERCURE_HUB}`, //el servidor
              `${data["@id"]}/participants`, //el topic
              (data: SessionInterface) => dispatch(fetchParticipants()) //la accion cuando me devuelve los datos
            );
          }
          //------------------------------------------------------------
        )
      );
  }, [state.session.session?.["@id"]]);

  return (
    <div>
      <table className="table-auto">
        <thead>
          <tr>
            <th>Evento</th>
            <th>Invitados</th>
            <th>Desde</th>
            <th>Hasta</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sessions?.map((session) => (
            <tr key={session["@id"]}>
              <td>
                <Link to={`${Paths.admin_sessions}/${session.id}`}>
                  <span>{session.title}</span>
                </Link>
              </td>
              <td>{state.session.participants?.length}</td>
              <td>
                {session.start &&
                  dateFormat(session.start, "dd/mm/yyyy  HH:MM")}
              </td>
              <td>
                {session.end && dateFormat(session.end, "dd/mm/yyyy  HH:MM")}
              </td>
              <td>
                <div
                  className={classNames(
                    "w-4 h-4 rounded-full",
                    { "bg-green-500": session.status === "active" },
                    { "bg-red-500": session.status === "finished" },
                    { "bg-gray-500": session.status === "pending" }
                  )}
                ></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Sessions;
