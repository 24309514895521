import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import React, { ReactElement } from "react";
import { FieldErrors } from "react-hook-form";
import { FormErrorMessage } from "../ErrorMessage";
import TextArea from "../TextArea";

type InputProps = {
  label?: string;
  errors: FieldErrors;
  help?: string;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;
// tomado de https://react-hook-form.com/ts#UseFormReturn

const RHFTextArea = React.forwardRef<HTMLTextAreaElement, InputProps>(
  ({ errors, className, help, label, ...props }, ref): ReactElement => {
    return (
      <div className={classNames("mb-6", className)} aria-live="polite">
        <TextArea
          label={label}
          help={help}
          {...props}
          ref={ref}
          required={false}
        />
        <ErrorMessage
          errors={errors}
          name={props.name as any}
          render={({ messages }) =>
            messages && (
              <div>
                {Object.entries(messages).map(([type, message]) => (
                  <FormErrorMessage key={type} className="mt-1">
                    {message}
                  </FormErrorMessage>
                ))}
              </div>
            )
          }
        />
      </div>
    );
  }
);

export default RHFTextArea;
