import classNames from "classnames";
import { Participant } from "../../../../interfaces/participant";
import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { selectUserGranted } from "../../../../redux/securitySlice";
import { useDispatch } from "react-redux";
import {
  delegateMyVote,
  delegateVote,
  kickParticipant,
  removeParticipant,
} from "../../../../redux/sessionSlice";
import { Session } from "../../../../interfaces/session";
import { Popover } from "@headlessui/react";

function ParticipantDetails({
  setDelegating,
  delegating,
  participant,
  session,
}: {
  setDelegating: (participant?: Participant) => void;
  delegating?: Participant;
  participant: Participant;
  session: Session;
}) {
  const user = useSelector((state: RootState) => state.security.user);
  const dispatch: AppDispatch = useDispatch();

  const userGranted = useSelector((state: RootState) =>
    selectUserGranted(state)
  );

  return user?.["@id"] !== participant.user["@id"] ? (
    <Menu as="div" className="relative text-left">
      <div>
        <div className="flex justify-center w-full rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          <div className="flex w-full">
            <div
              className={classNames(
                "w-5 h-5 rounded-full mr-2 flex-shrink-0 text-center",
                participant.joined ? "bg-green-500" : "bg-gray-400"
              )}
            >
              {participant.delegate && (
                <Popover className="relative">
                  <Popover.Button>
                    <span className="text-white font-xs font-medium">D</span>
                  </Popover.Button>

                  <Popover.Panel className="absolute z-10">
                    <div className="w-44 bg-black text-white text-left rounded-md p-1">
                      {participant.delegate?.user.displayName}
                    </div>
                  </Popover.Panel>
                </Popover>
              )}
            </div>
            <div className="flex-1 text-left">
              {delegating &&
                !participant.delegated &&
                !participant.delegate && (
                  <button
                    className="text-xs text-blue-500 underline mr-3"
                    onClick={() =>
                      dispatch(
                        delegateVote(
                          delegating["@id"],
                          participant["@id"],
                          () => setDelegating(undefined)
                        )
                      )
                    }
                  >
                    {participant["@id"] !== delegating["@id"]
                      ? "delegar"
                      : "cancelar"}
                  </button>
                )}
              {participant.user.displayName}{" "}
              {participant.delegated && (
                <Popover className="relative inline-block">
                  <Popover.Button>
                    <span className="text-red-600">+1</span>
                  </Popover.Button>

                  <Popover.Panel className="absolute z-10">
                    <div className="w-44 bg-black text-white rounded-md p-1">
                      {participant.delegated.user.displayName}
                    </div>
                  </Popover.Panel>
                </Popover>
              )}
            </div>
          </div>
          <Menu.Button>
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5 flex-shrink-0"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div className="py-1">
            {userGranted.includes("ROLE_ADMIN") && (
              <>
                {/* <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() =>
                        dispatch(delegateVote(participant["@id"], null))
                      }
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2 text-sm text-left"
                      )}
                    >
                      Dejar de delegar
                    </button>
                  )}
                </Menu.Item> */}
                {/* {participant.delegated ? (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() =>
                          dispatch(delegateVote(participant["@id"], null))
                        }
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-sm text-left"
                        )}
                      >
                        Dejar de delegar
                      </button>
                    )}
                  </Menu.Item>
                ) : (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setDelegating(participant)}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-sm text-left"
                        )}
                      >
                        Delegar voto
                      </button>
                    )}
                  </Menu.Item>
                )} */}
                {participant.delegate ? (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() =>
                          dispatch(delegateVote(participant["@id"], null))
                        }
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-sm  text-left"
                        )}
                      >
                        Dejar de delegar
                      </button>
                    )}
                  </Menu.Item>
                ) : (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setDelegating(participant)}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-sm  text-left"
                        )}
                      >
                        Delegar voto
                      </button>
                    )}
                  </Menu.Item>
                )}
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() =>
                        dispatch(removeParticipant(participant["@id"]))
                      }
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2 text-sm  text-left"
                      )}
                    >
                      Quitar de la lista
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() =>
                        dispatch(kickParticipant(participant["@id"]))
                      }
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2 text-sm text-left"
                      )}
                    >
                      Marcar como desconectado
                    </button>
                  )}
                </Menu.Item>
              </>
            )}
            {/* {userGranted.includes("ROLE_PARTICIPANT") && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => dispatch(delegateMyVote(participant["@id"]))}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block w-full px-4 py-2 text-sm text-left"
                    )}
                  >
                    Delegar mi voto a este usuario
                  </button>
                )}
              </Menu.Item>
            )} */}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  ) : (
    <div className="flex w-full px-4 py-2 text-sm font-medium">
      <div
        className={classNames(
          " w-5 h-5 rounded-full mr-2 flex-shrink-0 text-center",
          participant.joined ? "bg-green-500" : "bg-gray-400"
        )}
      >
        {participant.delegate && (
          <Popover className="relative">
            <Popover.Button>
              <span className="text-white font-xs font-medium">D</span>
            </Popover.Button>

            <Popover.Panel className="absolute z-10">
              <div className="w-44 bg-black text-white rounded-md p-1">
                {participant.delegate?.user.displayName}
              </div>{" "}
            </Popover.Panel>
          </Popover>
        )}
      </div>
      <div className="flex-1 text-left">
        {participant.user.displayName}{" "}
        {participant.delegated && <span className="text-red-600">+1</span>}
      </div>
    </div>
  );
}

export default ParticipantDetails;
