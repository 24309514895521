import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { Paths } from "../../App";
import Forbidden403 from "../../pages/Forbidden403";
import { RootState } from "../../redux/store";

function RequireAuth({
  children,
  role,
}: {
  children: JSX.Element;
  role?: string;
}) {
  const state = useSelector((state: RootState) => state);
  let location = useLocation();

  if (!state.security.user) {
    return <Navigate to={Paths.login} state={{ from: location }} replace />;
  }
  if (role && !state.security.user.roles?.includes(role))
    return <Forbidden403 />;

  return children;
}

export default RequireAuth;
