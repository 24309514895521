import classNames from "classnames";
import React from "react";

interface Props {
  white?: boolean;
}

const Loader: React.FC<Props & React.HTMLProps<HTMLElement>> = ({
  white,
  className,
}) => {
  return (
    <div className={classNames(className, white ? "spinner white" : "spinner")}>
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
};

export default Loader;
