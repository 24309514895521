import React from "react";
import Sessions from "./components/Sessions";

function Dashboard() {
  return (
    <div className="container max-w-5xl px-4 py-4 mx-auto sm:px-6 lg:px-8 flex-1">
      <Sessions />
    </div>
  );
}

export default Dashboard;
