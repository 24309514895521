import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import React, { FC, ReactElement } from "react";
import { FieldErrors } from "react-hook-form";
import { FormErrorMessage } from "../ErrorMessage";
import Input from "../Input";

type InputProps = {
  label?: string;
  errors?: FieldErrors;
  help?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;
// tomado de https://react-hook-form.com/ts#UseFormReturn

const RHFInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ errors, className, help, label, ...props }, ref): ReactElement => {
    return (
      <div className={classNames("mb-6", className)} aria-live="polite">
        <Input label={label} help={help} {...props} ref={ref} />
        <ErrorMessage
          errors={errors}
          name={props.name as any}
          render={({ messages }) =>
            messages && (
              <div>
                {Object.entries(messages).map(([type, message]) => (
                  <FormErrorMessage key={type} className="mt-1">
                    {message}
                  </FormErrorMessage>
                ))}
              </div>
            )
          }
        />
      </div>
    );
  }
);

export default RHFInput;
