import React from "react";

function NoMatch404() {
  return (
    <div className="h-screen w-full flex justify-center items-center">
      <div className="text-5xl font-extrabold text-gray-600">404</div>
    </div>
  );
}

export default NoMatch404;
