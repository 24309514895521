import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import RHFTextArea from "../../components/forms/rhf/RHFTextArea";
import { mapErrors } from "../../helper/formErrorsHelper";
import { Question } from "../../interfaces/question";
import { AppDispatch, RootState } from "../../redux/store";

function QuestionForm({ onSuccess }: { onSuccess: () => void }) {
  const form = useForm<Question>({
    mode: "onBlur",
    criteriaMode: "all",
  });
  const session = useSelector((state: RootState) => state.session.session);
  const dispatch: AppDispatch = useDispatch();

  /**
   * Actualiza pregunta
   */
  const onSubmit = (data: Question) => {
    if (session) data = { ...data, session: session?.["@id"] };
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/questions`, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
        form.reset();
        onSuccess && onSuccess();
      } else if (response.status === 422)
        response.json().then((data) => {
          mapErrors(form, data.violations);
        });
    });
  };

  return (
    <div>
      <div>
        <p className="mb-6">
          Puedes mandar una propuesta de moción para ser considerada por el
          resto de los participantes. Recuerda incluir en las notas el nombre de
          al menos dos participantes que secunden tu propuesta.
        </p>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <RHFTextArea
            errors={form.formState.errors}
            {...form.register("text", {
              required: "Indica el texto de la moción.",
              minLength: { value: 20, message: "Demasiado corto." },
            })}
            required
            label="Texto de la moción"
            className="mb-6"
          />
          <RHFTextArea
            errors={form.formState.errors}
            {...form.register("notes")}
            label="Notas a la mesa"
            className="mb-6"
            help="Puedes usar este campo para enviar una nota que solo la mesa podrá ver."
          />
          <button
            type="submit"
            className="px-3 py-4 w-full bg-slate-600 rounded-lg text-white m-auto border-2 border-slate-800 active:bg-slate-500"
          >
            Enviar propuesta de moción
          </button>
        </form>
      </div>
    </div>
  );
}

export default QuestionForm;
