import classNames from "classnames";
import React, { useState } from "react";
import Input from "../../../../components/forms/Input";
import { Answer } from "../../../../interfaces/answer";

function EditAnswer({ answer }: { answer: Answer }) {
  const [text, setText] = useState<string>(answer.text || "");
  const [error, setError] = useState<boolean>(false);

  const submit = () => {
    fetch(
      answer["@id"]
        ? `${process.env.REACT_APP_BACKEND_URL}${answer["@id"]}`
        : `${process.env.REACT_APP_BACKEND_URL}/api/answers`,
      {
        credentials: "include",
        method: answer["@id"]?"PUT":"POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...answer, text: text }),
      }
    ).then((response) => {
      if (response.ok) {
        response.json().then((data: Answer) => {
          setText(data.text || "");
          setError(false);
        });
      } else setError(true);
    });
  };
  return (
    <div className="flex-1">
      <Input
        required
        className={classNames("mb-0", error && "border-red-400")}
        value={text}
        onChange={(e) => setText(e.target.value)}
        onBlur={() => submit()}
      />
    </div>
  );
}

export default EditAnswer;
