//
//   Le da formato a un numero de cedula intercalando los puntos donde van
//
export function documentNormalize(input: string) {
  if (!input) return input;
  return input.replace(/[^A-Za-z0-9]/g, "");
  let chars = Array.from(input.replace(/[^A-Za-z0-9]/g, ""));
  //let chars = Array.from(input);
  if (chars.length < 2) return chars.join("");
  chars.splice(-1, 0, "-");
  if (chars.length > 5) chars.splice(-5, 0, ".");
  if (chars.length > 9) chars.splice(-9, 0, ".");
  return chars.join("");
}
