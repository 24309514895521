import * as router from "react-router-dom";

/**
 * REACT ROUTER useLocation whit typed state
 * https://github.com/remix-run/react-router/pull/7326
 */
export function useLocationWithState<T>() {
  type L = router.Location & { state: T };

  return router.useLocation() as L;
}
