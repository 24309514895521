import React, { useState } from "react";
import { useSelector } from "react-redux";
import MemberSearchInput from "../../../../components/sherpa/MemberSearchInput";
import { Participant } from "../../../../interfaces/participant";
import { Session } from "../../../../interfaces/session";
import { selectUserGranted } from "../../../../redux/securitySlice";
import { RootState } from "../../../../redux/store";
import ParticipantDetails from "./ParticipantDetails";

function Participants({
  session,
  admin,
}: {
  session: Session;
  admin: boolean;
}) {
  const participants = useSelector(
    (state: RootState) => state.session.participants
  );
  const [delegating, setDelegating] = useState<Participant>();

  const userGranted = useSelector((state: RootState) =>
    selectUserGranted(state)
  );

  return (
    <div className="max-h-96">
      <div className="p-2 bg-slate-100 sm:w-72 border border-gray-300">
        {userGranted.includes("ROLE_ADMIN") && (
          <MemberSearchInput onChange={console.log} onError={console.error} />
        )}
        <div className="overflow-scroll h-96">
          {participants
            ?.filter((p) => userGranted.includes("ROLE_ADMIN") || p.joined)
            .map((participant) => (
              <ParticipantDetails
                setDelegating={setDelegating}
                delegating={delegating}
                participant={participant}
                session={session}
                key={participant["@id"]}
              />
            ))}
        </div>
      </div>
      {userGranted.includes("ROLE_ADMIN") && (
        <div className="px-1 py-1 text-gray-500">
          Conectados{" "}
          <span className="font-semibold">
            {participants?.filter((p) => p.joined).length}
          </span>{" "}
          de <span className="font-semibold">{participants?.length}</span>
        </div>
      )}
    </div>
  );
}

export default Participants;
