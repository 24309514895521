import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import { CollectionInterface } from "../../interfaces/api";
import { Session } from "../../interfaces/session";
import { RootState } from "../store";

interface sessionsState {
  sessions?: Session[];
  active?: Session;
  isLoading: boolean;
  requested: boolean;
  error: string | null;
}

const initialState: sessionsState = {
  isLoading: false,
  requested: false,
  error: null,
};

const sessionsSlice = createSlice({
  name: "admin_sessions",
  initialState: initialState,
  reducers: {
    fetchStart(state) {
      state.isLoading = true;
      state.error = null;
      state.sessions = undefined;
    },
    fetchSuccess(state, action: PayloadAction<Session[]>) {
      state.isLoading = false;
      state.requested = true;
      state.error = null;
      state.sessions = action.payload;
    },
    fetchError(state) {
      state.isLoading = false;
      state.requested = true;
    },
    updateSession(state, action: PayloadAction<Session>) {
      state.isLoading = false;
      state.requested = true;
      state.error = null;
      state.sessions = state.sessions?.map((session) =>
        session["@id"] === action.payload["@id"] ? action.payload : session
      );
    },
  },
});

const { actions, reducer } = sessionsSlice;

export const { fetchStart, fetchError, fetchSuccess, updateSession } = actions;

/******************************************************************************
 * https://redux.js.org/tutorials/essentials/part-5-async-logic#thunk-functions
 ******************************************************************************/

/**
 * Obtiene los datos del miembros del servidor.
 * Llamado al cargar el sitio por privmera vez
 */
export const fetchSessions =
  (onSuccess?: () => void) => async (dispatch: Dispatch) => {
    dispatch(fetchStart());
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/sessions`,
      {
        credentials: "include",
      }
    );
    if (!res.ok) dispatch(fetchError());
    else {
      res
        .json()
        .then((data: CollectionInterface<Session>) =>
          dispatch(fetchSuccess(data["hydra:member"]))
        );
      onSuccess && onSuccess();
    }
  };

export default reducer;
