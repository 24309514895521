import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Init from "../../../components/Init";
import { Answer } from "../../../interfaces/answer";
import { CollectionInterface } from "../../../interfaces/api";
import { Question } from "../../../interfaces/question";
import { Session as SessionInterface } from "../../../interfaces/session";
import { Vote } from "../../../interfaces/vote";
import { fetchParticipants, fetchSession } from "../../../redux/sessionSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import { mercureSubscribe } from "../../../utils/mercure";

function Results() {
  const state = useSelector((state: RootState) => state.session);
  const session = state.session;
  const dispatch: AppDispatch = useDispatch();
  const { sessionId } = useParams();

  useEffect(() => {
    sessionId && dispatch(fetchSession(sessionId));
  }, [sessionId]);

  // useLayoutEffect(() => {
  //   state.session &&
  //     dispatch(
  //       fetchParticipants()
  //     );
  // }, [state.session]);

  if (!session) return <Init />;

  return (
    <div className="container max-w-5xl px-4 py-4 mx-auto sm:px-6 lg:px-8 flex-1">
      <h2 className="font-bold text-2xl mb-10">{session.title}</h2>
      <div className="divide-y">
        {session.questions
          ?.filter((q) => q.status === "accepted")
          .map((q) => (
            <QuestionResults question={q} key={q["@id"]} />
          ))}
      </div>
    </div>
  );
}

export default Results;

function QuestionResults({ question }: { question: Question }) {
  const [votes, setVotes] = useState<Vote[]>();

  useEffect(() => {
    fetchVotes(question["@id"]);
  }, [question]);

  const fetchVotes = (questionId: string) => {
    let url = new URL(`${process.env.REACT_APP_BACKEND_URL}/api/votes`);
    url.searchParams.append("question", questionId);

    fetch(url.href, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        response
          .json()
          .then((data: CollectionInterface<Vote>) =>
            setVotes(data["hydra:member"])
          );
      }
    });
  };

  return (
    <div className="py-10">
      {question.text && <h3 className="font-bold mb-10">{question.text}</h3>}
      {question.answers?.map((a) => (
        <AnswerResults
          question={question}
          answer={a}
          votes={votes}
          key={a["@id"]}
        />
      ))}
      <AnswerResults question={question} votes={votes} />
    </div>
  );
}

function AnswerResults({
  question,
  answer,
  votes,
}: {
  question: Question;
  answer?: Answer;
  votes?: Vote[];
}) {
  let filteredVotes: Vote[] =
    //votes?.filter((v) => v.answer === answer?.["@id"] || null) || [];
    votes?.filter((v) =>
      answer ? v.answer === answer["@id"] : v.answer === null
    ) || [];

  return (
    <div>
      <h3 className="font-semibold p-2 mt-10">
        {answer?.text || "Abstenciones"}
      </h3>
      {votes && (
        <div className="bg-gray-200 flex p-2 items-center">
          <div className="font-semibold w-24">
            {filteredVotes.length}{" "}
            {filteredVotes.length === 1 ? "voto" : "votos"}
          </div>
          <div className="text-xs">
            {filteredVotes.map((vote, index, votes) => {
              return (
                <span key={index}>
                  {vote.user?.displayName &&
                    vote.user?.displayName +
                      (votes.length !== index + 1 ? ", " : "")}
                </span>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
