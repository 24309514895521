import React, { useEffect, useRef, useState } from "react";

import { PlusIcon } from "@heroicons/react/outline";
import { useQuery } from "react-query";
import { User } from "../../interfaces/user";
import { CollectionInterface } from "../../interfaces/api";
import classNames from "classnames";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { addParticipant } from "../../redux/sessionSlice";

interface props {
  onChange: () => void;
  onError: () => void;
}

export default function MemberSearchInput({ onChange, onError }: props) {
  const [input, setInput] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch: AppDispatch = useDispatch();

  //------------ BUSQUEDA DE ITEMS  ----------
  const { isLoading, error, data, isFetched } = useQuery<
    User[] | undefined,
    Error
  >(["users", { input }], () => fetchUsers());

  async function fetchUsers() {
    if (!input.length) return [];
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/api/users`);

    url.searchParams.append("displayName", input);

    //Consulta a la api
    const response = await fetch(url.href, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      return response.json().then((data: CollectionInterface<User>) => {
        return data["hydra:member"];
      });
    }
  }

  //============== Hago click arriba de un miembro =============
  const userSelect = (user: User) => {
    setInput(""); //Cierro los resultados
    inputRef.current?.focus();

    dispatch(addParticipant(user["@id"]));
  };

  return (
    <div className="flex flex-col  mb-2 border-b border-gray-200">
      <div className="relative my-2">
        <input
          type="text"
          ref={inputRef}
          aria-autocomplete="both"
          aria-haspopup="false"
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          autoFocus={false}
          spellCheck="false"
          value={input}
          placeholder="Agrega un miembro por su nombre ..."
          onChange={(e) => setInput(e.target.value)}
          className="block w-full pl-10 placeholder-gray-500 bg-gray-100 border-0 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 sm:text-sm"
        />
        <div className="absolute inset-y-0 flex items-center pointer-events-none left-2">
          <PlusIcon className="w-8 h-8 text-gray-400" aria-hidden="true" />
        </div>
      </div>

      <div className="relative flex-1">
        {/* {localError ? (
          <ErrorMessage message={localError} />
        ) : (
          <MembersList
            members={searchResult.members}
            memberSelect={memberSelect}
          />
        )} */}
        {data && <UsersList users={data} userSelect={userSelect} />}
        {isLoading && (
          <div className="absolute z-10 w-full px-2 bg-white border border-t-0 border-gray-200 drop-shadow-md">
            <PlaceHolder />
            <PlaceHolder />
            <PlaceHolder />
          </div>
        )}
      </div>
    </div>
  );
}

function UsersList({
  users,
  userSelect,
}: {
  users: User[];
  userSelect: (user: User) => void;
}) {
  return (
    <div
      className={classNames(
        users.length > 0 &&
          "z-10 absolute w-full px-2 bg-white border border-t-0 border-gray-200 drop-shadow-md"
      )}
    >
      {users.map((user) => (
        <button
          key={user["@id"]}
          className="flex content-center w-full p-3 text-left border-b"
          onClick={() => userSelect(user)}
        >
          <div className="ml-3">
            <p>{user.displayName}</p>
            <p className="text-sm text-gray-700">
              {user.username}{" "}
              <span className="p-1 text-white uppercase bg-indigo-500 rounded-sm text-2xs">
                educador
              </span>
            </p>
          </div>
        </button>
      ))}
    </div>
  );
}

function PlaceHolder() {
  return (
    <div className="w-full max-w-sm p-4 ml-12">
      <div className="flex space-x-4 animate-pulse">
        <div className="flex-1 py-1 space-y-4">
          <div className="w-3/4 h-4 bg-gray-200 rounded"></div>
          <div className="space-y-2">
            <div className="h-4 bg-gray-200 rounded"></div>
            <div className="w-5/6 h-4 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
