import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import { CollectionInterface } from "../interfaces/api";
import { ErrorFromServer } from "../interfaces/ErrorFromServer";
import { Participant } from "../interfaces/participant";
import { Question } from "../interfaces/question";
import { Session } from "../interfaces/session";
import { Vote } from "../interfaces/vote";
import { AppDispatch, RootState } from "./store";

interface state {
  session?: Session;
  participants?: Participant[];
  question?: Question;
  myVote?: Vote;
  isLoading: boolean;
  requested: boolean;
  error: string | null;
}

const initialState: state = {
  isLoading: false,
  requested: false,
  error: null,
};

const sessionSlice = createSlice({
  name: "session",
  initialState: initialState,
  reducers: {
    fetchStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    fetchSuccess(state, action: PayloadAction<Session>) {
      state.isLoading = false;
      state.requested = true;
      state.error = null;
      state.session = action.payload;
    },
    fetchError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
      state.requested = true;
    },
    setVote(state, action: PayloadAction<Vote | undefined>) {
      state.myVote = action.payload;
    },
    setParticipants(state, action: PayloadAction<Participant[]>) {
      state.participants = action.payload.sort((a, b) =>
        a.user.displayName.localeCompare(b.user.displayName)
      );
    },
  },
});

const { actions, reducer } = sessionSlice;

export const {
  fetchStart,
  fetchError,
  fetchSuccess,
  setVote,
  setParticipants,
} = actions;

//     S   E   L   E   C   T   O   R   E   S
// https://redux.js.org/usage/deriving-data-selectors#createselector-overview

const selectSession = (slice: state) => slice.session;
export const selectParticipants = (slice: state) => slice.participants;
const selectUserId = (slice: state, userId?: string) => userId;

// Devuelve de forma dinámica la preunta activa
export const selectActiveQuestion = createSelector(selectSession, (session) => {
  return session?.questions?.find(
    (question) => question["@id"] === session.activeQuestion
  );
});

// Devuelve mis datos como participante
export const selectMeInSession = createSelector(
  [selectParticipants, selectUserId],
  (participants, userId) => {
    return participants?.find(
      (participant) => participant.user["@id"] === userId
    );
  }
);

/******************************************************************************
 * https://redux.js.org/tutorials/essentials/part-5-async-logic#thunk-functions
 ******************************************************************************/

/**
 * Obtiene los datos del miembros del servidor.
 * Llamado al cargar el sitio por privmera vez
 */
export const fetchSession =
  (sessionId: string, onSuccess?: (data: Session) => void) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchStart());
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/sessions/${sessionId}`,
      {
        credentials: "include",
      }
    );
    if (!res.ok) dispatch(fetchError("Error"));
    else {
      res.json().then((data: Session) => {
        dispatch(fetchSuccess(data));
        onSuccess && onSuccess(data);
      });
    }
  };

export default reducer;

/**
 * Marca una pregunta como la activa
 */
export const joinSession =
  () => async (dispatch: AppDispatch, getState: any) => {
    let state = getState() as RootState;
    let session = state.session.session;
    if (!session) throw Error("Session not found.");

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL + session["@id"]}/join`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      }
    );
    if (res.ok) {
      res.json();
      // .then((data: Session) => session && dispatch(fetchSession(session.id)));
    }
  };

/**
 *
 * Manda el voto
 */
export const postMyVote =
  (answerId?: string, onSuccess?: () => void) =>
  async (dispatch: Dispatch, getState: any) => {
    let state = getState() as RootState;
    let session = state.session.session;
    if (!session) throw Error("Session not found.");
    if (!session.activeQuestion) throw Error("No active question.");

    let vote: Vote = {
      question: session.activeQuestion,
      answer: answerId, //undefined = abstención
    };
    dispatch(setVote(vote));

    const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/votes`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(vote),
    });
    if (res.ok) {
      res.json().then((data: Vote) => {
        dispatch(setVote(data));
        onSuccess && onSuccess();
      });
    } else dispatch(setVote());
  };

/**
 * Marca una pregunta como la activa
 */
export const setActiveQuestion =
  (questionId: string | null) =>
  async (dispatch: AppDispatch, getState: any) => {
    let state = getState() as RootState;
    let session = state.session.session;
    if (!session) throw Error("Session not found.");

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL + session["@id"]}`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ activeQuestion: questionId }),
      }
    );
    if (res.ok) {
      res.json();
      // .then((data: Session) => session && dispatch(fetchSession(session.id)));
    }
  };

/**
 * Rechaza una pregunta
 */
export const rejectQuestion =
  (questionId: string) => async (dispatch: AppDispatch, getState: any) => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL + questionId}/reject`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      }
    );
    if (res.ok) {
      res.json();
      // .then((data: Session) => session && dispatch(fetchSession(session.id)));
    }
  };

/**
 * Agrega un participante
 */
export const addParticipant =
  (userId: string) => async (dispatch: AppDispatch, getState: any) => {
    let state = getState() as RootState;
    let session = state.session.session;
    if (!session) throw Error("Session not found.");

    if (
      state.session.participants?.find(
        (participant) => participant.user["@id"] === userId
      )
    )
      throw new Error("Usuario ya está en la lista.");

    // const res = await fetch(
    //   `${process.env.REACT_APP_BACKEND_URL}/api/participants`,
    //   {
    //     method: "POST",
    //     credentials: "include",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       session: session["@id"],
    //       user: userId,
    //     }),
    //   }
    // );

    let participants = [
      ...(state.session.participants?.map((p) => {
        return { id: p["@id"] };
      }) || []),
      {
        session: session["@id"],
        user: userId,
      },
    ];
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}${session["@id"]}`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          participants: participants,
        }),
      }
    );
    if (res.ok) dispatch(fetchParticipants());
  };

/**
 *
 * Delega el voto
 */
export const delegateMyVote =
  (participantId?: string, onSuccess?: () => void) =>
  async (dispatch: Dispatch, getState: any) => {
    let state = getState() as RootState;
    let session = state.session.session;
    if (!session) throw Error("Session not found.");

    let me = state.session.participants?.find(
      (participant) => participant.user["@id"] === state.security.user?.["@id"]
    );

    if (!me?.["@id"]) throw Error("User not found.");

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL + me["@id"]}/delegate`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ delegate: participantId || null }),
      }
    );
    if (res.ok) {
      res.json().then((data: Participant) => {
        onSuccess && onSuccess();
      });
    }
  };

/**
 *
 * Delega el voto
 */
export const delegateVote =
  (fromId: string, toId: string | null, onSuccess?: () => void) =>
  async (dispatch: Dispatch, getState: any) => {
    let state = getState() as RootState;
    let session = state.session.session;
    if (!session) throw Error("Session not found.");

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL + fromId}/delegate`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ delegate: fromId === toId ? null : toId }),
      }
    );
    if (res.ok) {
      res.json().then((data: Participant) => {
        onSuccess && onSuccess();
      });
    } else if (res.status === 422) {
      res.json().then((data) => {
        if (
          data.violations.find(
            (v: ErrorFromServer) =>
              (v.code = "23bd9dbf-6b9b-41cd-a99e-4844bcf3077f")
          )
        )
          alert("El usuario ya tiene un voto delegado.");
      });
    }
  };

/**
 *
 * Marca como desconectado
 */
export const kickParticipant =
  (participantId: string, onSuccess?: () => void) =>
  async (dispatch: Dispatch, getState: any) => {
    let state = getState() as RootState;
    let session = state.session.session;
    if (!session) throw Error("Session not found.");

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL + participantId}/kick`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      }
    );
    if (res.ok) {
      res.json().then((data: Participant) => {
        onSuccess && onSuccess();
      });
    }
  };

/**
 *
 * Marca como desconectado
 */
export const removeParticipant =
  (participantId: string, onSuccess?: () => void) =>
  async (dispatch: Dispatch, getState: any) => {
    let state = getState() as RootState;
    let session = state.session.session;
    if (!session) throw Error("Session not found.");

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL + participantId}`,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      }
    );
    if (res.ok) {
      res.json().then((data: Participant) => {
        onSuccess && onSuccess();
      });
    }
  };

/**
 *  Obtiene la lista de partipantes
 */
export const fetchParticipants =
  (onSuccess?: (data: Session) => void) =>
  async (dispatch: Dispatch, getState: any) => {
    let state = getState() as RootState;
    let session = state.session.session;
    if (!session) throw Error("Session not found.");

    let url = new URL(`${process.env.REACT_APP_BACKEND_URL}/api/participants`);
    url.searchParams.append("session", session["@id"]);

    fetch(url.href, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((data: CollectionInterface<Participant>) => {
          onSuccess && session && onSuccess(session);
          dispatch(setParticipants(data["hydra:member"]));
        });
      }
    });
  };

/**
 *
 * Abre / cierra el evento
 */
export const toggleSessionStatus =
  () => async (dispatch: Dispatch, getState: any) => {
    let state = getState() as RootState;
    let session = state.session.session;
    if (!session) throw Error("Session not found.");

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL + session["@id"]}`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          session.end ? { start: new Date(), end: null } : { end: new Date() }
        ),
      }
    );
    if (res.ok) {
      res.json().then((data: Session) => dispatch(fetchSuccess(data)));
    }
  };
