import { has } from "lodash";
import { UseFormReturn } from "react-hook-form";
import { ErrorFromServer, FormError } from "../interfaces/ErrorFromServer";

/**
 *
 * Mapea los errores enviados por api platforms
 * con los campos del formulario.
 *
 */
export const mapErrors = (
  form: UseFormReturn<any>,
  violations: ErrorFromServer[],
  formErrors?: FormError[],
  setFormErrors?: React.Dispatch<React.SetStateAction<FormError[]>>
) => {
  let errors: {
    [name: string]: { [rule: string]: string }; //  { birthDate: {required: "This is required" , minLength: "This is minLength"}}
  } = {};
  violations.forEach((violation) => {
    //El error devuelto tiene que ver con un campo del formulario?
    //Tengo que usar has de lodash para verificar que existan propiedades anidadas como address.phoneNumber
    let name = has(form.getValues(), violation.propertyPath)
      ? violation.propertyPath
      : false;

    //Si es asi agrego el error a la lista de errores del campo
    if (name)
      (errors[name] = errors[name] || [])[violation.code] = violation.message;
    //Sino lo muestra de otra manera (al principio del formulario como un error general)
    else if (formErrors && setFormErrors)
      setFormErrors((formErrors) => [
        ...formErrors,
        {
          code: violation.code,
          message: violation.message,
        },
      ]); //Lo sumo a la lista de errores generales
  });
  // Ahora la lista de errores de cada campo los paso definitivamente
  // como errores usando setError
  Object.entries(errors).forEach(([key, value]) => {
    //@ts-ignore
    form.setError(key, { types: value }, { shouldFocus: true });
  });
};
