import React from "react";
import dots from "../../media/dots.svg";
import WhatsAppHelp from "../WhatsAppHelp";

const BlankLayout = ({ children }: { children: JSX.Element }) => {
  return (
    <div className="flex flex-col h-screen">
      <img src={dots} alt="" className="absolute right-10 top-10 h-32 -z-10" />
      {children}
      <WhatsAppHelp />
    </div>
  );
};

export default BlankLayout;
