import { CalendarIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Session } from "../../../../interfaces/session";
import { toggleSessionStatus } from "../../../../redux/sessionSlice";
import { AppDispatch, RootState } from "../../../../redux/store";

function Header({ session }: { session: Session }) {
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector((state: RootState) => state.security.user);

  return (
    <div className="mb-8 w-full">
      <h2 className="font-bold text-2xl sm:text-3xl flex items-center w-full text-left">
        <CalendarIcon className="w-12 h-auto mr-2" />
        <div className="flex-1">{session.title}</div>
      </h2>
      {user?.roles?.includes("ROLE_ADMIN") && (
        <div className="flex items-center pl-14">
          <div
            className={classNames(
              "w-4 h-4 rounded-full flex-shrink-0",
              session.status === "active" ? "bg-green-500" : "bg-gray-400"
            )}
          ></div>
          <span className="mx-4">
            {session.status === "active" ? "EVENTO ACTIVO" : "EVENTO INACTIVO"}
          </span>
          <button
            onClick={() => dispatch(toggleSessionStatus())}
            className="text-blue-500 underline"
          >
            {session.status === "active" ? "terminar" : "comenzar"}
          </button>
        </div>
      )}
    </div>
  );
}

export default Header;
