/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import React, { FC, Fragment, useState } from "react";

interface Props {
  children: JSX.Element;
  title?: string;
  afterLeave?: () => void;
  wide?: boolean;
}

const Modal: FC<Props> = ({ children, title, afterLeave, wide = false }) => {
  const [open, setOpen] = useState(true);

  return (
    <Transition.Root show={open} as={Fragment} appear={true}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            afterLeave={() => afterLeave && afterLeave()}
          >
            {/* <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"> */}
            <div
              className={classNames(
                "overflow-auto inline-block align-bottom bg-white sm:rounded-lg px-4 pt-5 pb-6 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle w-full h-screen fixed sm:relative top-0 sm:h-auto sm:p-5",
                wide ? "sm:max-w-lg" : "sm:max-w-sm"
              )}
            >
              <div className="absolute top-5 right-5">
                <button
                  type="button"
                  className=" text-gray-700 hover:text-gray-500 focus:outline-none"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              {title && (
                <Dialog.Title as="h3" className="leading-6 font-semibold ">
                  {title}
                </Dialog.Title>
              )}

              <div className="mt-4">{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
