import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import Modal from "../../../../components/Modal";
import { Question } from "../../../../interfaces/question";
import { Session } from "../../../../interfaces/session";
import EditQuestion from "../../question/EditQuestion";

function PendingQuestions({ session }: { session: Session }) {
  const [edit, setEdit] = useState<Question>();

  let questions = session.questions?.filter(
    (question) => question.status === "pending"
  );

  return (
    <div>
      <h3 className="font-semibold text-lg mb-2 pb-2 border-b border-gray-200">
        Mociones pendientes
      </h3>
      <div className="space-y-2">
        {(!questions || questions.length === 0) && (
          <div className="text-gray-500 py-4">... esperando propuestas</div>
        )}
        {questions?.map((question) => (
          <button
            onClick={() => setEdit(question)}
            className="p-4 bg-gray-200 rounded-lg text-left block w-full"
            key={question["@id"]}
          >
            <div>{question.text}</div>
            {question.notes && (
              <div className="my-6 italic">
                NOTA A LA MESA: {question.notes}
              </div>
            )}
            <div className="mt-2 text-xs">
              Propuesto por <span>{question.author?.displayName}</span>{" "}
              <ReactTimeAgo date={new Date(question.created)} />
            </div>
          </button>
        ))}
        <Link to="results" className="text-blue-500 underline mt-2">
          ver resultados de mociones
        </Link>
      </div>

      {edit && (
        <Modal afterLeave={() => setEdit(undefined)}>
          <EditQuestion
            questionId={edit["@id"]}
            close={() => setEdit(undefined)}
          />
        </Modal>
      )}
    </div>
  );
}

export default PendingQuestions;
