import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  delegateMyVote,
  joinSession,
  selectMeInSession,
} from "../../redux/sessionSlice";
import { AppDispatch, RootState } from "../../redux/store";
import ActiveQuestion from "./components/ActiveQuestion";
import girl from "../../media/quorum-girl.svg";
import QuestionForm from "./QuestionForm";
import Modal from "../../components/Modal";
import Participants from "../admin/session/components/Participants";
import Header from "../admin/session/components/Header";

function ActiveSession() {
  const [showQuestionForm, setShowQuestionForm] = useState(false);
  const session = useSelector((state: RootState) => state.session.session);
  const participants = useSelector(
    (state: RootState) => state.session.participants
  );
  const me = useSelector((state: RootState) =>
    selectMeInSession(state.session, state.security.user?.["@id"])
  );
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (!me?.joined) dispatch(joinSession());
  }, [me?.["@id"]]);

  if (!participants) return null;
  if (!session) return null;

  if (!me)
    return (
      <div className="flex-1 flex items-center justify-center">
        <div className="font-black text-5xl text-gray-500">
          No estás en la lista de invitados. Pidele al organizador que te
          agregue.
        </div>
      </div>
    );

  if (me.delegate)
    return (
      <div className="flex-1 flex items-center justify-center">
        <div className="text-center">
          <div className="font-black text-5xl text-gray-500">
            Estás delegando tu voto
          </div>
          <div>
            <button
              className="text-blue-500 underline"
              onClick={() => dispatch(delegateMyVote())}
            >
              terminar
            </button>
          </div>
        </div>
      </div>
    );

  return (
    <div className="container max-w-5xl px-4 py-4 mx-auto sm:px-6 lg:px-8 flex-1">
      <Header session={session} />
      <div className="flex">
        <div className="sm:block hidden">
          <img src={girl} alt="" className="w-3/4 h-auto m-auto mb-10" />
        </div>
        <div className="flex flex-col w-full sm:w-auto">
          <button
            onClick={() => setShowQuestionForm(true)}
            className="w-full mb-8 px-3 py-2 bg-slate-600 rounded-lg text-white m-auto border-2 border-slate-800 active:bg-slate-500"
          >
            Enviar propuesta de moción
          </button>
          <h3 className="font-semibold text-lg mb-2 pb-2 border-b border-gray-200">
            Participantes conectados
          </h3>
          <Participants session={session} admin={false} />
          {/* <button
            onClick={() => setShowQuestionForm(true)}
            className="w-full mt-8 px-3 py-2 bg-slate-600 rounded-lg text-white m-auto border-2 border-slate-800 active:bg-slate-500"
          >
            Enviar propuesta de moción
          </button> */}
        </div>
        {/* ActiveSession {me?.user.displayName} -{" "}
        {me.joined ? "JOINED" : "NOJOINED"} */}
      </div>
      {showQuestionForm && (
        <Modal
          afterLeave={() => setShowQuestionForm(false)}
          title="Enviar propuesta de moción"
          wide
        >
          <QuestionForm onSuccess={() => setShowQuestionForm(false)} />
        </Modal>
      )}

      <ActiveQuestion />
    </div>
  );
}

export default ActiveSession;
