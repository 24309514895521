import React, { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router";
import { fetchSessions } from "../../redux/admin/sessionsSlice";
import { AppDispatch } from "../../redux/store";

function Admin() {
  const dispatch: AppDispatch = useDispatch();

  /**
   * Se cual sea la pagina que voy a cargar quiero
   * obtener algunos datos del servidor
   */
  useLayoutEffect(() => {
    dispatch(fetchSessions());
  }, []);

  return <Outlet />;
}

export default Admin;
