import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
/** @ts-ignore */
import Identicon from "react-identicons";
import { Menu, Transition } from "@headlessui/react";
import Loader from "../Loader";
import classNames from "classnames";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/securitySlice";
import { Paths } from "../../App";

function UserBlock() {
  const security = useSelector((state: RootState) => state.security);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="mt-2">
      {security.isLoading && <Loader className="w-8 h-8 text-gray-400" />}
      {security.requested &&
        (security.user ? (
          <div>
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="flex items-center text-gray-400 bg-gray-100 rounded-full hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                <div className="relative">
                  <Identicon
                    size={36}
                    className="inline-block rounded-full"
                    string={security.user.username}
                    bg="#d0dee5"
                    fg="#a6b1b7"
                  />
                  <span className="absolute bottom-0 right-0 block w-2.5 h-2.5 bg-green-400 rounded-full ring-2 ring-white" />
                </div>
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute left-0 w-72 mt-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-4 py-1 space-y-1 text-sm">
                    <div className="px-2 py-2 border-b">
                      <div>
                        Conectado como{" "}
                        <span className="italic font-semibold">
                          @{security.user.username}
                        </span>
                      </div>
                      <div className="text-xs">{security.user.displayName}</div>
                    </div>

                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() =>
                            dispatch(logout(() => navigate(Paths.login)))
                          }
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm w-full text-center"
                          )}
                        >
                          Salir
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        ) : (
          <Link to="/login"></Link>
        ))}
    </div>
  );
}

export default UserBlock;
