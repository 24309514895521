import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Session as SessionInterface } from "../../../interfaces/session";
import { fetchParticipants, fetchSession } from "../../../redux/sessionSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import { mercureSubscribe } from "../../../utils/mercure";
import ActiveQuestion from "./components/ActiveQuestion";
import Header from "./components/Header";
import Participants from "./components/Participants";
import PendingQuestions from "./components/PendingQuestions";

function Session() {
  const state = useSelector((state: RootState) => state.session);
  const dispatch: AppDispatch = useDispatch();
  const { sessionId } = useParams();

  useEffect(() => {
    sessionId &&
      dispatch(
        fetchSession(
          sessionId,
          //------------------------------------------------------------
          //   ONSUCCESS SE SUSCRIBE A CAMBIOS REPORTADOS POR MERCURE HUB
          (data: SessionInterface) => {
            mercureSubscribe(
              `${process.env.REACT_APP_MERCURE_HUB}`, //el servidor
              `${process.env.REACT_APP_BACKEND_URL}${data["@id"]}`, //el topic
              (data: SessionInterface) => dispatch(fetchSession(data.id)) //la accion cuando me devuelve los datos
            );
          }
          //------------------------------------------------------------
        )
      );
  }, [sessionId]);

  useLayoutEffect(() => {
    state.session &&
      dispatch(
        fetchParticipants(
          //------------------------------------------------------------
          //   ONSUCCESS SE SUSCRIBE A CAMBIOS REPORTADOS POR MERCURE HUB
          (data: SessionInterface) => {
            mercureSubscribe(
              `${process.env.REACT_APP_MERCURE_HUB}`, //el servidor
              `${data["@id"]}/participants`, //el topic
              (data: SessionInterface) => dispatch(fetchParticipants()) //la accion cuando me devuelve los datos
            );
          }
          //------------------------------------------------------------
        )
      );
  }, [state.session?.["@id"]]);

  return (
    <div className="container max-w-5xl px-4 py-4 mx-auto sm:px-6 lg:px-8 flex-1">
      {state.session && (
        <>
          <Header session={state.session} />
          {state.session.status === "active" && (
            <div className="flex">
              <div className="flex-1 mr-2">
                <ActiveQuestion />
                <PendingQuestions session={state.session} />
              </div>
              <div className="flex flex-col">
                <h3 className="font-semibold text-lg mb-2 pb-2 border-b border-gray-200">
                  Lista de participantes
                </h3>
                <Participants session={state.session} admin={true} />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Session;
