import { DevTool } from "@hookform/devtools";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../App";
import Button from "../../components/forms/Button";
import { GeneralErrorMessage } from "../../components/forms/ErrorMessage";
import RHFDateInput from "../../components/forms/rhf/RHFDateInput";
import RHFInput from "../../components/forms/rhf/RHFInput";
import BlankLayout from "../../components/layout/BlankLayout";
import { documentNormalize } from "../../helper/documentNormalizer";
import { useLocationWithState } from "../../helper/useLocationWithState";
import logo from "../../media/logo-msu-blanco.svg";
import { login } from "../../redux/securitySlice";
import { AppDispatch, RootState } from "../../redux/store";

export default function Login() {
  const location = useLocationWithState<{ from?: string } | undefined>();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const security = useSelector((state: RootState) => state.security);

  const { control, register, handleSubmit, formState, setValue } = useForm({
    criteriaMode: "all",
    defaultValues: { username: "", password: "", test: "1980-1-12" },
  });

  return (
    <BlankLayout>
      <div className="text-white bg-purple-800 h-screen sm:h-auto p-4 sm:p-8 m-auto max-w-md sm:border sm:rounded-md w-full flex content-center items-center">
        <DevTool control={control} />

        <div className="w-full">
          <div className="text-center mb-10">
            <img src={logo} alt="" className="w-1/2 m-auto mb-6" />
            <h1 className="font-semibold sm:text-lg">
              Identificate para continuar
            </h1>
            <p>Acceso restringido para educadores</p>
          </div>
          <form
            onSubmit={handleSubmit((data) =>
              dispatch(
                login(
                  { username: data.username, password: data.password },
                  // () => navigate(location.state?.from || Paths.home)
                  () => navigate(location.state?.from || `${Paths.home}`)
                )
              )
            )}
          >
            {security.error &&
              {
                "Invalid credentials.": (
                  <GeneralErrorMessage>
                    Los datos no son correctos o no estás en la lista de
                    habilitados.
                  </GeneralErrorMessage>
                ),
                default: (
                  <GeneralErrorMessage>{security.error}</GeneralErrorMessage>
                ),
              }[security.error]}
            <RHFInput
              {...register("username", {
                required: "Indica tu número de cedula",
                onBlur: (e) =>
                  setValue("username", documentNormalize(e.target.value)),
              })}
              label="Cédula"
              errors={formState.errors}
            />
            <RHFInput
              label="Fecha de nacimiento (ej: 10/03/2002)"
              {...register("password", {
                required:
                  "Verifica tu fecha de nacimiento (recuerda incluir los ceros)",
              })}
              errors={formState.errors}
            />
            {/* <RHFDateInput
              label="Fecha de nacimiento"
              control={control}
              name="password"
              errors={formState.errors}
            /> */}

            <Button
              icon="lockPad"
              className="w-full"
              disabled={formState.isSubmitting}
              loading={formState.isSubmitting}
              type="submit"
            >
              Ingresar
            </Button>
          </form>
        </div>
      </div>
    </BlankLayout>
  );
}
