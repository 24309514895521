import React, { FC } from "react";
import classNames from "classnames";
import { XCircleIcon } from "@heroicons/react/solid";
import { ValidateResult } from "react-hook-form";

export type FormErrorMessageProps = {
  children?: ValidateResult;
  className?: string;
};

export const FormErrorMessage: FC<FormErrorMessageProps> = ({
  children,
  className,
}) => (
  <p className={classNames("text-sm text-left block text-red-600", className)}>
    {children}
  </p>
);

export const GeneralErrorMessage: FC<FormErrorMessageProps> = ({
  children,
  className,
}) => (
  <div className={classNames("rounded-md bg-red-50 p-4 my-2", className)}>
    <div className="flex">
      <div className="flex-shrink-0">
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <p className="text-sm text-red-700">{children}</p>
      </div>
    </div>
  </div>
);
