import { TrashIcon } from "@heroicons/react/outline";
import React, { useLayoutEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import ReactTimeAgo from "react-time-ago";
import Button from "../../../components/forms/Button";
import RHFInput from "../../../components/forms/rhf/RHFInput";
import RHFTextArea from "../../../components/forms/rhf/RHFTextArea";
import { mapErrors } from "../../../helper/formErrorsHelper";
import { Answer } from "../../../interfaces/answer";
import { Question } from "../../../interfaces/question";
import { rejectQuestion, setActiveQuestion } from "../../../redux/sessionSlice";
import { AppDispatch } from "../../../redux/store";
import EditAnswer from "./components/EditAnswer";

function EditQuestion({
  questionId,
  close,
}: {
  questionId: string;
  close: () => void;
}) {
  const dispatch: AppDispatch = useDispatch();
  const [question, setQuestion] = useState<Question>();
  const form = useForm<Question>({
    mode: "onBlur",
    criteriaMode: "all",
  });

  useLayoutEffect(() => {
    fetchQuestion(questionId);
  }, [questionId]);

  const fetchQuestion = (questionId: string) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}${questionId}`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((data: Question) => {
          setQuestion(data);
          form.reset(data);
        });
      }
    });
  };

  const addAnswer = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/answers`, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ text: " ", question: question?.["@id"] }),
    }).then((response) => {
      if (response.ok) {
        response.json().then((data: Answer) => {
          question &&
            setQuestion({
              ...question,
              answers: [...(question.answers || []), data],
            });
        });
      }
    });
  };

  const deleteAnswer = (answerId: string) => {
    question &&
      setQuestion({
        ...question,
        answers: question.answers?.filter(
          (answer) => answer["@id"] !== answerId
        ),
      });
    fetch(`${process.env.REACT_APP_BACKEND_URL}${answerId}`, {
      credentials: "include",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          console.log(data);
        });
      }
    });
  };

  // const removeAnswer = (answerId: string) => {
  //   if (!question) return;
  //   let data: Question = {
  //     ...question,
  //     answers: question?.answers?.filter(
  //       (answer) => answer["@id"] !== answerId
  //     ),
  //   };
  //   setQuestion(data);
  //   form.reset(data);
  //   form.handleSubmit(onSubmit)();
  // };

  /**
   * Actualiza pregunta
   */
  const onSubmit = (data: Question) => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}${data["@id"]}`, {
      credentials: "include",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
        //answers: data.answers?.map((answer) => answer["@id"]),
      }),
    }).then((response) => {
      if (response.status === 422)
        response.json().then((data) => {
          mapErrors(form, data.violations);
        });
    });
  };

  return (
    <div>
      <form onBlur={form.handleSubmit(onSubmit)}>
        <RHFTextArea
          errors={form.formState.errors}
          {...form.register("text")}
          label="Propuesta"
          required
          className="mb-2"
        />
        {question && (
          <>
            {question.notes && (
              <div className="my-6 italic">
                NOTA A LA MESA: {question.notes}
              </div>
            )}
            <div className="mb-5 text-xs">
              Propuesto por <span>{question.author?.displayName}</span>{" "}
              <ReactTimeAgo date={new Date(question.created)} />
            </div>
          </>
        )}
        <div className="space-y-3">
          {question?.answers?.map((answer, index) => (
            <div className="flex justify-center items-center" key={index}>
              <div className="bg-gray-900 rounded-full text-white text-xs p-1 mr-2 h-6 w-6 text-center">
                {index + 1}
              </div>
              <EditAnswer answer={answer} key={index} />
              <div>
                <button
                  onClick={() => answer["@id"] && deleteAnswer(answer["@id"])}
                  type="button"
                >
                  <TrashIcon className="h-5 w-auto" />
                </button>
              </div>
              {/* {index > 2 && (
                <button
                  onClick={() => removeAnswer(answer["@id"])}
                  type="button"
                >
                  <TrashIcon className="w-10 h-10 px-2" />
                </button>
              )} */}
            </div>
          ))}
          <button onClick={() => addAnswer()} type="button">
            + agregar respuesta
          </button>
        </div>
      </form>
      <Button
        onClick={() => {
          dispatch(setActiveQuestion(questionId));
          close();
        }}
        className="w-full mt-6"
      >
        Marcar como activa
      </Button>
      <button
        onClick={() => {
          dispatch(rejectQuestion(questionId));
          close();
        }}
        className="w-full mt-1 text-blue-400 underline text-center"
      >
        Rechazar moción
      </button>
    </div>
  );
}

export default EditQuestion;
