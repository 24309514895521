import { normalize } from "./dataAccess";
import { CollectionInterface } from "../interfaces/api";

export const mercureSubscribe = (
  hubURL: string,
  topic: string,
  setData: Function
) => {
  const url = new URL(hubURL, window.origin);
  url.searchParams.append("topic", topic);
  const eventSource = new EventSource(url.toString());
  eventSource.addEventListener("message", (event) => {
    setData(normalize(JSON.parse(event.data)));
  });

  return eventSource;
};
