import {
  ArrowRightIcon,
  CheckIcon,
  LockClosedIcon,
  PencilAltIcon,
  PencilIcon,
  SaveAsIcon,
  SaveIcon,
  TrashIcon,
  UserAddIcon,
  XIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";
import React from "react";
import Loader from "../Loader";

interface ButtonProps {
  color?: "primary" | "secondary" | "light" | "dark" | "link";
  scale?: "xs" | "sm" | "base" | "lg" | "xl" | "2xl";
  icon?:
    | "arrow"
    | "lockPad"
    | "check"
    | "x"
    | "addUser"
    | "pencil"
    | "trash"
    | "save";
  iconPlacement?: "left" | "right";
  loading?: boolean;
}

const Button: React.FC<ButtonProps & React.HTMLProps<HTMLButtonElement>> = ({
  color,
  scale,
  children,
  type,
  className,
  icon,
  iconPlacement,
  loading,
  ...props
}) => {
  //Defino las clases de acuerdo a las opciones pasadas
  const buttonClasses = new Set();
  const iconClasses = new Set([""]);

  //Base
  buttonClasses.add(
    "relative border border-transparent font-light rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 group inline-flex items-center justify-center"
  );

  //Tamaño
  scale = scale ?? "base"; //Defualt

  switch (scale) {
    case "xs":
      buttonClasses.add("py-1 px-1 text-sm sm:text-xs");
      iconClasses.add("-ml-2 mr-1 h-5 w-5");
      break;
    case "sm":
      buttonClasses.add("py-2 px-2 text-sm sm:text-sm");
      iconClasses.add("-ml-0.5 mr-2 h-5 w-5");
      break;
    case "base":
      buttonClasses.add("py-4 px-4 text-sm sm:text-base");
      iconClasses.add("-ml-2 mr-2 h-5 w-5");
      break;
    case "lg":
      buttonClasses.add("py-6 px-5 text-sm sm:text-base");
      iconClasses.add("-ml-0.5 mr-2 h-5 w-5");
      break;
  }

  //Color
  color = color ?? "primary"; //Defualt
  switch (color) {
    case "primary":
      buttonClasses.add(
        "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 text-white disabled:bg-indigo-400"
      );
      iconClasses.add("group-hover:text-indigo-400 text-indigo-200");
      break;
    case "secondary":
      buttonClasses.add(
        "bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 text-white disabled:opactiy-40 disabled:bg-purple-400"
      );
      iconClasses.add("group-hover:text-purple-500 text-purple-400");
      break;
    case "light":
      buttonClasses.add(
        "bg-white border-gray-400 hover:bg-gray-500 focus:ring-gray-500 disabled:bg-gray-300"
      );
      iconClasses.add("group-hover:text-gray-700 text-gray-700");
      break;
  }

  //Icon placement
  iconPlacement = iconPlacement ?? "left";
  buttonClasses.add(iconPlacement === "right" ? "flex-row-reverse" : "");

  //
  buttonClasses.add(className);
  return (
    <button className={classNames(Array.from(buttonClasses))} {...props}>
      {icon && (
        <span className={classNames("pl-3", loading ? "invisible" : "")}>
          {
            {
              arrow: (
                <ArrowRightIcon
                  className={classNames(Array.from(iconClasses))}
                  aria-hidden="true"
                />
              ),
              lockPad: (
                <LockClosedIcon
                  className={classNames(Array.from(iconClasses))}
                  aria-hidden="true"
                />
              ),
              check: (
                <CheckIcon
                  className={classNames(Array.from(iconClasses))}
                  aria-hidden="true"
                />
              ),
              x: (
                <XIcon
                  className={classNames(Array.from(iconClasses))}
                  aria-hidden="true"
                />
              ),
              addUser: (
                <UserAddIcon
                  className={classNames(Array.from(iconClasses))}
                  aria-hidden="true"
                />
              ),
              pencil: (
                <PencilIcon
                  className={classNames(Array.from(iconClasses))}
                  aria-hidden="true"
                />
              ),
              trash: (
                <TrashIcon
                  className={classNames(Array.from(iconClasses))}
                  aria-hidden="true"
                />
              ),
              save: (
                <SaveIcon
                  className={classNames(Array.from(iconClasses))}
                  aria-hidden="true"
                />
              ),
            }[icon]
          }
        </span>
      )}
      <span className={classNames("uppercase", loading ? "invisible" : "")}>
        {children}
      </span>
      {loading && <Loader white />}
    </button>
  );
};

export default Button;
