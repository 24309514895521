import React from "react";
import "../../styles/components/home.css";
import WhatsAppHelp from "../WhatsAppHelp";
import logo from "../../media/logo-msu-oficial.svg";
import wosm from "../../media/wosm.svg";
import fb from "../../media/fb.svg";
import tw from "../../media/tw.svg";
import insta from "../../media/insta.svg";
import microsoft from "../../media/microsoft.svg";
import mercadopago from "../../media/mercadopago.svg";
import heart from "../../media/heart-regular.svg";
import UserBlock from "../security/UserBlock";

const HomeLayout = ({ children }: { children: JSX.Element }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      {children}
      <WhatsAppHelp />
      <Footer />
    </div>
  );
};

export default HomeLayout;

export function Header() {
  return (
    <div
      id="header"
      className="container flex justify-between max-w-5xl px-4 py-4 mx-auto sm:px-6 lg:px-8 print:hidden"
    >
      <UserBlock />
      <div>
        <img
          src={logo}
          className="h-14 sm:h-18"
          alt="Movimiento Scout del Uruguay"
        />
      </div>
    </div>
  );
}

export function Footer() {
  return (
    <div id="footer" className="print:hidden">
      <div className="hidden container sm:flex max-w-5xl px-4 py-2 mx-auto mt-10 border-t border-gray-200 sm:px-6 lg:px-8">
        <img
          src={wosm}
          alt="Organización Mundial del Movimiento Scout"
          className="h-8 mr-5 md:h-10"
        />
        <p className="flex-1 text-xs text-gray-700">
          <span className="font-semibold">Movimiento Scout del Uruguay</span>
          <br />
          Andrés Martínez Trueba 1187
          <br />
          <a href="tel:+59824108840">(+598) 2410 8840</a>
          <br />
          <a href="mailto:pagos@msu.edu.uy" className="text-blue-600 underline">
            pagos@msu.edu.uy
          </a>
        </p>
        <div className="invisible md:visible">
          <h3 className="mb-2 text-xs font-semibold text-gray-700">
            Con el apoyo de
          </h3>
          <img
            src={microsoft}
            alt="Microsoft"
            className="inline-block mr-5 h-7"
          />
          <img
            src={mercadopago}
            alt="MercadoPago"
            className="inline-block h-7"
          />
        </div>
      </div>

      <div className="bg-gray-100 mt-2">
        <div className="container flex items-center max-w-5xl px-4 py-1 mx-auto sm:px-6 lg:px-8">
          <p className="flex-1 text-sm text-gray-600">
            Hecho con <img src={heart} alt="amor" className="inline h-3" /> por
            y para Scouts
          </p>
          <a
            href="https://www.facebook.com/MovimientoScoutdelUruguay"
            rel="noreferrer"
            target="_blank"
          >
            <img src={fb} alt="" className="h-4 mx-1" />
          </a>
          <a
            href="https://www.instagram.com/msu_oficial/"
            rel="noreferrer"
            target="_blank"
          >
            <img src={insta} alt="" className="h-4 mx-1" />
          </a>
          <a
            href="https://twitter.com/MScoutUy"
            rel="noreferrer"
            target="_blank"
          >
            <img src={tw} alt="" className="h-4 mx-1" />
          </a>
        </div>
      </div>
    </div>
  );
}
